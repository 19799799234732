<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护管理经费</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="保护管理经费"
                    prop="BHGLJF"
                    :style="{ width: '80%' }"
                >
                    <!--  @input="toDecimal($event, 'BHGLJF', 8)" -->
                    <el-input
                        v-model="formdata.dataDic.BHGLJF"
                        :disabled="!isEdit"
                        placeholder="请填写保护管理经费,最多不能超过一亿"
                        onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                        @input="changeMoney()"
                        @blur="blurMoney('BHGLJF')"
                        @focus="focusMoney('BHGLJF')"
                        clearable
                    >
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="保护管理单位"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.BHGLDW"
                        :disabled="!isEdit"
                        placeholder="请填写保护管理单位"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="经费项目类型"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.JFXMLX"
                        :disabled="!isEdit"
                        placeholder="请选择经费项目费类型"
                    >
                        <el-option
                            v-for="{ LABEL, CODE } in enumObj.JFXMLX || []"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="保护管理经费类型"
                    prop="BHGLJFLX"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.BHGLJFLX"
                        :disabled="!isEdit"
                        placeholder="请选择保护管理经费类型"
                    >
                        <el-option
                            v-for="{ LABEL, CODE } in enumObj.BHGLJFLX"
                            :key="LABEL"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="经费获取时间" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.JFHQSJ"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                        placeholder="请选择经费获取日期"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    BHGLJF: null,
                    BHGLDW: null,
                    JFXMLX: null,
                    BHGLJFLX: null,
                    JFHQSJ: null,
                },
                fileids: "",
                itemCode: "204",
                metaJson: [],
            },
            rules: {
                BHGLJF: [
                    {
                        required: true,
                        message: "请填写保护管理经费",
                        trigger: ["blur", "change"],
                    },
                ],
                BHGLJFLX: [
                    {
                        required: true,
                        message: "请选择保护管理经费类型",
                        trigger: "blur",
                    },
                ],
            },
            toMoneyProps: ["BHGLJF"],
        };
    },
    methods: {
        // 金额判断 最大1亿
        changeMoney() {
            if (this.formdata.dataDic.BHGLJF > 100000000) {
                this.formdata.dataDic.BHGLJF = 100000000;
            }
        },
    },
};
</script>

<style></style>
